import React from "react";
import { graphql } from "gatsby";
import { Box } from "rebass/styled-components";
import Layout from "../components/Layout";
import Container from "../components/Container";
import RoomTypesList from "../components/RoomTypesList";

export default class RoomsListPage extends React.Component {
  render() {
    const { data, location } = this.props;
    const scrollToReservation = (location.state && location.state.scrollToReservation) || false;
    const linkToPricelist =
      (location.state && location.state.linkToPricelist) || false;
    const { edges: rooms } = data.allWordpressWpMphbRoomType;

    return (
      <Layout>
        {rooms && (
          <>
            <Container>
              <h2>{rooms.length > 1 ? "Naše chaloupky" : "Naše chaloupka"}</h2>
            </Container>
            <Box style={{ position: "relative" }}>
              <RoomTypesList
                posts={rooms}
                scrollToReservation={scrollToReservation}
                linkToPricelist={linkToPricelist}
              />
            </Box>
          </>
        )}
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query RoomsListQuery {
    allWordpressWpMphbRoomType(sort: { fields: menu_order, order: ASC }) {
      edges {
        node {
          ...RoomTypesListFields
        }
      }
    }
  }
`;
